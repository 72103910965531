import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Access } from '../access'
const Ac = () => {
    return (
        <L10nProvider>
            <Access/>
        </L10nProvider>
    )
}

export default Ac;
